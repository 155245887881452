import ContentsTypes from "@shared/enums/contentsTypes";
import ContentsCustomTypes from "@project/enums/contentsCustomTypes.enum";
export const Mailto = {
    label: "Lien mailto",
    type: ContentsCustomTypes.MAILTO,
    editionModal: true,
    editionModalPosition: "top",
    icon: "at",
    children: [
        {
            label: "Texte",
            key: "text",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Email de destination",
            key: "recipient",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Objet",
            key: "subject",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Corps",
            key: "body",
            type: ContentsTypes.INPUT,
            value: "",
        },
    ],
};
