import ContentsTypes from "@shared/enums/contentsTypes";
export const Subpage = {
    label: "Sous-page avec bouton",
    type: ContentsTypes.SUBPAGE,
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "",
            key: "page",
            type: ContentsTypes.PAGE,
            value: "",
        },
        {
            label: "",
            key: "subtitle",
            type: ContentsTypes.EDITABLE_INPUT,
            value: "",
        },
        {
            label: "",
            key: "dynamicPart",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
    ],
};
