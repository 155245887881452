import { ArticleSection } from "./articleSection.descriptor";
import { BlockWithTitle } from "./blocWithTitle.descriptor";
import { ContactForm } from "./contactForm.descriptor";
import { DescriptionBlock } from "./descriptionBlock.descriptor";
import { GeographicMap } from "./geographicMap.descriptor";
import { Iframe } from "./iframe.descriptor";
import { Mailto } from "./mailto.descriptor";
import { PresentationBlock } from "./presentationBlock.descriptor";
import { PresentationCard } from "./presentationCard.descriptor";
import { PresentationBox } from "./presentationBox.descriptor";
import { Subpage } from "./subpage.descriptor";
import { SubpageSearch } from "./subpageSearch.descriptor";
import { TextBlockWithTitle } from "./textBlockWithTitle.descriptor";
import { Trombinoscope } from "./trombinoscope.descriptor";
import { DescriptionCardWithLogo } from "./descriptionCardWithLogo.descriptor";
import { TabsComponent } from "./tabsComponent.descriptor";
export default [
    ArticleSection,
    PresentationBox,
    Trombinoscope,
    BlockWithTitle,
    TextBlockWithTitle,
    DescriptionBlock,
    DescriptionCardWithLogo,
    PresentationBlock,
    PresentationCard,
    TabsComponent,
    Subpage,
    GeographicMap,
    SubpageSearch,
    ContactForm,
    Mailto,
    Iframe,
];
