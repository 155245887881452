import IframePreview from "@project/components/Iframe/IframePreview";
import ContentsTypes from "@shared/enums/contentsTypes";
import ContentsCustomTypes from "@project/enums/contentsCustomTypes.enum";
export const Iframe = {
    label: "Iframe",
    type: ContentsCustomTypes.IFRAME,
    icon: "address-card",
    editionModal: true,
    editionModalPosition: "top",
    Preview: IframePreview,
    children: [
        {
            label: "URL",
            key: "url",
            type: ContentsTypes.URL,
            value: "",
        },
        {
            label: "Height (px)",
            key: "height",
            type: ContentsTypes.INPUT_NUMBER,
            value: "",
        },
    ],
};
