import { latitudeRegex, longitudeRegex } from "@shared/utils/commonUtils";
import ContentsTypes from "@shared/enums/contentsTypes";
import ContentsCustomTypes from "@project/enums/contentsCustomTypes.enum";
export const GeographicMap = {
    label: "Carte géographique",
    type: ContentsCustomTypes.GEOGRAPHIC_MAP,
    icon: "map-marker-alt",
    editionModal: true,
    editionModalPosition: "top",
    requiredByKey: ["latitude", "longitude"],
    children: [
        {
            label: "Latitude",
            key: "latitude",
            type: ContentsTypes.INPUT,
            regexValidation: latitudeRegex,
            value: "",
        },
        {
            label: "Longitude",
            key: "longitude",
            type: ContentsTypes.INPUT,
            regexValidation: longitudeRegex,
            value: "",
        },
        {
            label: "Niveau de zoom initial",
            key: "initialZoom",
            type: ContentsTypes.SLIDER,
            min: "0",
            max: "16",
            step: "1",
            value: "10",
        },
        {
            label: "Nom du lieu",
            key: "name",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Adresse",
            key: "address",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Ville",
            key: "city",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Code postal",
            key: "postalCode",
            type: ContentsTypes.INPUT,
            value: "",
        },
    ],
};
