import ContentsTypes from "@shared/enums/contentsTypes";
import customTypes from "@project/enums/contentsCustomTypes.enum";
// eslint-disable-next-line react/prop-types
const getDynamicAdminContentsTypes = ({ type }) => {
    switch (type) {
        case ContentsTypes.EXPANSION_PANEL_DETAILS:
        case ContentsTypes.DYNAMIC:
            return [
                customTypes.ARTICLE_SECTION,
                customTypes.GEOGRAPHIC_MAP,
                customTypes.PRESENTATION_BLOCK,
                customTypes.PRESENTATION_CARD,
                customTypes.BLOCK_WITH_TITLE,
                customTypes.TEXT_BLOCK_WITH_TITLE,
                customTypes.TABS_COMPONENT,
                customTypes.DESCRIPTION_BLOCK,
                customTypes.DESCRIPTION_CARD_WITH_LOGO,
                customTypes.BLOCK_TITLE,
                customTypes.CONTACT_FORM,
                customTypes.MAILTO,
            ];
        case ContentsTypes.GRID:
            return [
                customTypes.PRESENTATION_BOX,
                customTypes.TROMBINOSCOPE,
                customTypes.PRESENTATION_BLOCK,
                customTypes.PRESENTATION_CARD,
                customTypes.DESCRIPTION_CARD_WITH_LOGO,
                customTypes.BLOCK_WITH_TITLE,
                customTypes.TEXT_BLOCK_WITH_TITLE,
                customTypes.DESCRIPTION_BLOCK,
                customTypes.BLOCK_TITLE,
                customTypes.CONTACT_FORM,
            ];
        default:
            return [];
    }
};
export default getDynamicAdminContentsTypes;
