import contentsTypes from "@shared/enums/contentsTypes";
import SubpageDynamicForm from "@project/components/adminForms/SubpageDynamicForm";
import SubpageSearchForm from "@project/components/adminForms/SubpageSearchForm";
import customTypes from "@project/enums/contentsCustomTypes.enum";
const getFormComponent = ({ type }) => {
    switch (type) {
        case customTypes.SUBPAGE_SEARCH:
            return SubpageSearchForm;
        case contentsTypes.SUBPAGE:
            return SubpageDynamicForm;
        default:
            return null;
    }
};
export default getFormComponent;
