import ContentsTypes from "@shared/enums/contentsTypes";
import TabsPreview from "@project/components/TabsComponent/TabsComponentPreview";
export const TabsComponent = {
    label: "Tabs component",
    type: ContentsTypes.TABS_COMPONENT,
    Preview: TabsPreview,
    icon: "address-card",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Onglets",
            key: "tabs",
            type: ContentsTypes.TABS,
            value: "",
        },
    ],
};
