import ContentsTypes from "@shared/enums/contentsTypes";
import DescriptionBlockPreview from "@project/components/DescriptionBlock/DescriptionBlockPreview";
import ContentsCustomTypes from "@project/enums/contentsCustomTypes.enum";
export const DescriptionBlock = {
    label: "Bloc de description",
    type: ContentsCustomTypes.DESCRIPTION_BLOCK,
    Preview: DescriptionBlockPreview,
    icon: "ballot",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Titre",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Couleur de fond",
            key: "backgroundColor",
            type: ContentsTypes.COLOR,
            value: "#FFFFFF",
        },
        {
            label: "Texte",
            key: "text",
            type: ContentsTypes.TEXT,
            value: "",
        },
    ],
};
