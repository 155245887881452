import InfoBlockIcon from "@shared/components/icons/InfoBlockIcon";
import ContentsTypes from "@shared/enums/contentsTypes";
import PresentationBlockPreview from "@project/components/PresentationBlock/PresentationBlockPreview";
import ContentsCustomTypes from "@project/enums/contentsCustomTypes.enum";
export const PresentationBlock = {
    label: "Bloc de présentation",
    type: ContentsCustomTypes.PRESENTATION_BLOCK,
    Preview: PresentationBlockPreview,
    IconComponent: InfoBlockIcon,
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Image",
            key: "image",
            type: ContentsTypes.IMAGE,
            value: "",
        },
        {
            label: "Alignement de l'image",
            key: "imagePosition",
            type: ContentsTypes.SELECT,
            options: [
                { label: "À gauche", value: "left" },
                { label: "À droite", value: "right" },
            ],
            value: "left",
        },
        {
            label: "Titre",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Sous titre",
            key: "subtitle",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Description",
            key: "description",
            type: ContentsTypes.SHORT_TEXT,
            value: "",
        },
        {
            label: "Note de bas",
            key: "footnote",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Couleur de fond",
            key: "backgroundColor",
            type: ContentsTypes.COLOR,
            value: "#FFFFFF",
        },
    ],
};
