import ContentsTypes from "@shared/enums/contentsTypes";
import ContentsCustomTypes from "@project/enums/contentsCustomTypes.enum";
export const SubpageSearch = {
    label: "Dernières pages publiées",
    type: ContentsCustomTypes.SUBPAGE_SEARCH,
    children: [
        {
            label: "Titre du contenu",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Nombre de pages à afficher (limité à 10 pages)",
            key: "perPage",
            type: ContentsTypes.INPUT_NUMBER,
            value: "",
        },
        {
            label: "Pages provenant du gabarit",
            key: "template",
            type: ContentsTypes.SELECT,
            options: [],
            value: "",
        },
        {
            label: "Pages provenant de la page parente",
            key: "pageParent",
            type: ContentsTypes.PAGE,
            value: "",
        },
    ],
};
