import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputForm from "@administration/components/editor/contentForms/InputForm";
import SelectForm from "@administration/components/editor/contentForms/SelectForm";
import SubpageForm from "@administration/components/editor/contentForms/SubpageForm";
import CmsHooks from "@shared/utils/register/CmsHooks";
import { HOOKS } from "@shared/utils/register/definitions";
const SubpageSearchForm = (props) => {
    const { content, onContentChange } = props;
    const { label, children } = content;
    const { id } = content;
    const titleChild = children?.find((c) => c.key === "title");
    const perPageChild = children?.find((c) => c.key === "perPage");
    const templateChild = children?.find((c) => c.key === "template");
    const pageParentChild = children?.find((c) => c.key === "pageParent");
    const templates = CmsHooks.getHook(HOOKS.TEMPLATES) || [];
    if (templateChild) {
        templateChild.options = [
            { label: "Aucun", value: "" },
            ...templates.map((t) => ({ label: t.label, value: t.key })),
        ];
    }
    const handleContentChange = (oldChild) => (newChild) => {
        onContentChange({
            ...content,
            children: children?.map((child) => {
                if (child === oldChild) {
                    return newChild;
                }
                return child;
            }),
        });
    };
    return (_jsxs("div", { children: [_jsx("h2", { style: { textAlign: "center" }, children: label }), _jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 6, children: titleChild && (_jsx(InputForm, { content: titleChild, onContentChange: handleContentChange(titleChild) })) }), _jsx(Grid, { item: true, xs: 6, children: perPageChild && (_jsx(InputForm, { content: perPageChild, onContentChange: handleContentChange(perPageChild) })) }), _jsx(Grid, { item: true, xs: 6, children: templateChild && (_jsx(SelectForm, { content: templateChild, onContentChange: handleContentChange(templateChild) })) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormControl, { children: _jsx(SubpageForm, { content: {
                                    ...pageParentChild,
                                    value: typeof pageParentChild?.value === "object"
                                        ? pageParentChild.value
                                        : pageParentChild?.value && {
                                            page: { id: pageParentChild.value },
                                        },
                                }, onContentChange: handleContentChange(pageParentChild), withLabel: false, canBeEmpty: true }) }) })] })] }, id));
};
export default SubpageSearchForm;
