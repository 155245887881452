import { jsx as _jsx } from "react/jsx-runtime";
import SubpageForm from "@administration/components/editor/contentForms/SubpageForm";
import ContentsTypes from "@shared/enums/contentsTypes";
const SubpageDynamicForm = (props) => {
    const { content, onContentChange } = props;
    const { children } = content;
    const subPageChild = children?.find((c) => c.type === ContentsTypes.PAGE);
    const { id } = content;
    const handleSubPageChange = (newSubpageChild) => {
        onContentChange({
            ...content,
            children: children?.map((child) => {
                if (child === subPageChild) {
                    return newSubpageChild;
                }
                return child;
            }),
        });
    };
    return (_jsx("div", { children: _jsx(SubpageForm, { content: subPageChild, onContentChange: handleSubPageChange }) }, id));
};
export default SubpageDynamicForm;
