import ContentsTypes from "@shared/enums/contentsTypes";
import ContentsCustomTypes from "@project/enums/contentsCustomTypes.enum";
export const PresentationBox = {
    label: "Boîte",
    type: ContentsCustomTypes.PRESENTATION_BOX,
    icon: "address-card",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Titre",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Sous titre",
            key: "subtitle",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Description",
            key: "description",
            type: ContentsTypes.SHORT_TEXT,
            value: "",
        },
        {
            label: "Icone",
            key: "icon",
            type: ContentsTypes.ICON,
            value: "",
        },
        {
            label: "Image",
            key: "image",
            type: ContentsTypes.IMAGE,
            value: "",
        },
    ],
};
