import InfoBlockIcon from "@shared/components/icons/InfoBlockIcon";
import ContentsTypes from "@shared/enums/contentsTypes";
import PresentationCardPreview from "@project/components/PresentationCard/PresentationCardPreview";
export const PresentationCard = {
    label: "Carte de présentation",
    type: ContentsTypes.PRESENTATION_CARD,
    Preview: PresentationCardPreview,
    IconComponent: InfoBlockIcon,
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Titre",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Image",
            key: "image",
            type: ContentsTypes.IMAGE,
            value: "",
        },
        {
            label: "Alignement de l'image",
            key: "imagePosition",
            type: ContentsTypes.SELECT,
            options: [
                { label: "À gauche", value: "left" },
                { label: "À droite", value: "right" },
            ],
            value: "left",
        },
        {
            label: "Description",
            key: "description",
            type: ContentsTypes.SHORT_TEXT,
            value: "",
        },
        {
            label: "Couleur de texte",
            key: "colorText",
            type: ContentsTypes.COLOR,
            value: "#343434",
        },
        {
            label: "Couleur de fond",
            key: "backgroundColor",
            type: ContentsTypes.COLOR,
            value: "#FFFFFF",
        },
    ],
};
